import type { Country } from '~/types/Country'

/**
 * A collection of all countries and data regarding their silly little flags.
 *
 * @deprecated I am **highly aware** there is probably a **better thing** to do for this.
 * For now i'll settle for the infamous *"I'll put this in a ts file instead of a vue2 mixin"* kinda energy.
 * Life can be disappointing sometimes.
 *
 * @remarks Names are kebab-cased at the EOF.
 */
export const countriesArray = [
  {
    id: '58',
    enabled: '1',
    code3l: 'FRA',
    code2l: 'FR',
    name: 'France',
    name_official: 'French Republic',
    flag_32: 'FR-32.png',
    flag_128: 'FR-128.png',
    latitude: '46.48372145',
    longitude: '2.60926281',
    zoom: '6',
  },
  {
    id: '244',
    enabled: '1',
    code3l: 'USA',
    code2l: 'US',
    name: 'United States',
    name_official: 'United States of America',
    flag_32: 'US-32.png',
    flag_128: 'US-128.png',
    latitude: '37.66895362',
    longitude: '-102.39256450',
    zoom: '4',
  },
  {
    id: '186',
    enabled: '1',
    code3l: 'GBR',
    code2l: 'GB',
    name: 'United Kingdom',
    name_official: 'United Kingdom of Great Britain and Northern Ireland',
    flag_32: 'GB-32.png',
    flag_128: 'GB-128.png',
    latitude: '53.36540813',
    longitude: '-2.72184767',
    zoom: '6',
  },
  {
    id: '17',
    enabled: '1',
    code3l: 'BEL',
    code2l: 'BE',
    name: 'Belgium',
    name_official: 'Kingdom of Belgium',
    flag_32: 'BE-32.png',
    flag_128: 'BE-128.png',
    latitude: '50.49593874',
    longitude: '4.46993600',
    zoom: '8',
  },
  {
    id: '251',
    enabled: '1',
    code3l: 'AFG',
    code2l: 'AF',
    name: 'Afghanistan',
    name_official: "République islamique d'Afghanistan",
    flag_32: 'AF-32.png',
    flag_128: 'AF-128.png',
    latitude: '33.838806',
    longitude: '66.026471',
    zoom: '7',
  },
  {
    id: '2',
    enabled: '1',
    code3l: 'ALB',
    code2l: 'AL',
    name: 'Albania',
    name_official: 'Republic of Albania',
    flag_32: 'AL-32.png',
    flag_128: 'AL-128.png',
    latitude: '41.00017358',
    longitude: '19.87170014',
    zoom: '7',
  },
  {
    id: '3',
    enabled: '1',
    code3l: 'DZA',
    code2l: 'DZ',
    name: 'Algeria',
    name_official: 'People\u2019s Democratic Republic of Algeria',
    flag_32: 'DZ-32.png',
    flag_128: 'DZ-128.png',
    latitude: '27.89861690',
    longitude: '3.19771194',
    zoom: '5',
  },
  {
    id: '5',
    enabled: '1',
    code3l: 'AGO',
    code2l: 'AO',
    name: 'Angola',
    name_official: 'Republic of Angola',
    flag_32: 'AO-32.png',
    flag_128: 'AO-128.png',
    latitude: '-12.16469683',
    longitude: '16.70933622',
    zoom: '6',
  },
  {
    id: '195',
    enabled: '1',
    code3l: 'AIA',
    code2l: 'AI',
    name: 'Anguilla',
    name_official: 'Anguilla',
    flag_32: 'AI-32.png',
    flag_128: 'AI-128.png',
    latitude: '18.22053521',
    longitude: '-63.06861300',
    zoom: '12',
  },
  {
    id: '6',
    enabled: '1',
    code3l: 'ATG',
    code2l: 'AG',
    name: 'Antigua and Barbuda',
    name_official: 'Antigua and Barbuda',
    flag_32: 'AG-32.png',
    flag_128: 'AG-128.png',
    latitude: '17.60157314',
    longitude: '-61.82262200',
    zoom: '10',
  },
  {
    id: '7',
    enabled: '1',
    code3l: 'ARG',
    code2l: 'AR',
    name: 'Argentina',
    name_official: 'Argentine Republic',
    flag_32: 'AR-32.png',
    flag_128: 'AR-128.png',
    latitude: '-38.01529308',
    longitude: '-64.97897469',
    zoom: '4',
  },
  {
    id: '8',
    enabled: '1',
    code3l: 'ARM',
    code2l: 'AM',
    name: 'Armenia',
    name_official: 'Republic of Armenia',
    flag_32: 'AM-32.png',
    flag_128: 'AM-128.png',
    latitude: '40.13475528',
    longitude: '45.01072318',
    zoom: '7',
  },
  {
    id: '9',
    enabled: '1',
    code3l: 'AUS',
    code2l: 'AU',
    name: 'Australia',
    name_official: 'Australia',
    flag_32: 'AU-32.png',
    flag_128: 'AU-128.png',
    latitude: '-26.29594646',
    longitude: '133.55540944',
    zoom: '4',
  },
  {
    id: '10',
    enabled: '1',
    code3l: 'AUT',
    code2l: 'AT',
    name: 'Austria',
    name_official: 'Republic of Austria',
    flag_32: 'AT-32.png',
    flag_128: 'AT-128.png',
    latitude: '47.63125476',
    longitude: '13.18776731',
    zoom: '7',
  },
  {
    id: '11',
    enabled: '1',
    code3l: 'AZE',
    code2l: 'AZ',
    name: 'Azerbaijan',
    name_official: 'Republic of Azerbaijan',
    flag_32: 'AZ-32.png',
    flag_128: 'AZ-128.png',
    latitude: '40.35321757',
    longitude: '47.46706372',
    zoom: '7',
  },
  {
    id: '13',
    enabled: '1',
    code3l: 'BHR',
    code2l: 'BH',
    name: 'Bahrain',
    name_official: 'Kingdom of Bahrain',
    flag_32: 'BH-32.png',
    flag_128: 'BH-128.png',
    latitude: '25.90740996',
    longitude: '50.65932354',
    zoom: '9',
  },
  {
    id: '14',
    enabled: '1',
    code3l: 'BGD',
    code2l: 'BD',
    name: 'Bangladesh',
    name_official: 'People\u2019s Republic of Bangladesh',
    flag_32: 'BD-32.png',
    flag_128: 'BD-128.png',
    latitude: '24.08273251',
    longitude: '90.49915527',
    zoom: '7',
  },
  {
    id: '16',
    enabled: '1',
    code3l: 'BLR',
    code2l: 'BY',
    name: 'Belarus',
    name_official: 'Republic of Belarus',
    flag_32: 'BY-32.png',
    flag_128: 'BY-128.png',
    latitude: '53.58628747',
    longitude: '27.95338900',
    zoom: '6',
  },
  {
    id: '17',
    enabled: '1',
    code3l: 'BEL',
    code2l: 'BE',
    name: 'Belgium',
    name_official: 'Kingdom of Belgium',
    flag_32: 'BE-32.png',
    flag_128: 'BE-128.png',
    latitude: '50.49593874',
    longitude: '4.46993600',
    zoom: '8',
  },
  {
    id: '18',
    enabled: '1',
    code3l: 'BLZ',
    code2l: 'BZ',
    name: 'Belize',
    name_official: 'Belize',
    flag_32: 'BZ-32.png',
    flag_128: 'BZ-128.png',
    latitude: '17.21153631',
    longitude: '-88.01424956',
    zoom: '8',
  },
  {
    id: '19',
    enabled: '1',
    code3l: 'BEN',
    code2l: 'BJ',
    name: 'Benin',
    name_official: 'Republic of Benin',
    flag_32: 'BJ-32.png',
    flag_128: 'BJ-128.png',
    latitude: '9.37180859',
    longitude: '2.29386134',
    zoom: '7',
  },
  {
    id: '201',
    enabled: '1',
    code3l: 'BMU',
    code2l: 'BM',
    name: 'Bermuda',
    name_official: 'the Bermudas',
    flag_32: 'BM-32.png',
    flag_128: 'BM-128.png',
    latitude: '32.31995785',
    longitude: '-64.76182765',
    zoom: '12',
  },
  {
    id: '20',
    enabled: '1',
    code3l: 'BTN',
    code2l: 'BT',
    name: 'Bhutan',
    name_official: 'Kingdom of Bhutan',
    flag_32: 'BT-32.png',
    flag_128: 'BT-128.png',
    latitude: '27.50752756',
    longitude: '90.43360300',
    zoom: '8',
  },
  {
    id: '182',
    enabled: '1',
    code3l: 'BOL',
    code2l: 'BO',
    name: 'Bolivia',
    name_official: 'Plurinational State of Bolivia',
    flag_32: 'BO-32.png',
    flag_128: 'BO-128.png',
    latitude: '-16.74518128',
    longitude: '-65.19265691',
    zoom: '6',
  },
  {
    id: '21',
    enabled: '1',
    code3l: 'BIH',
    code2l: 'BA',
    name: 'Bosnia Herzegovina',
    name_official: 'Bosnia and Herzegovina',
    flag_32: 'BA-32.png',
    flag_128: 'BA-128.png',
    latitude: '44.00040856',
    longitude: '17.81640910',
    zoom: '7',
  },
  {
    id: '22',
    enabled: '1',
    code3l: 'BWA',
    code2l: 'BW',
    name: 'Botswana',
    name_official: 'Republic of Botswana',
    flag_32: 'BW-32.png',
    flag_128: 'BW-128.png',
    latitude: '-22.18279485',
    longitude: '24.22344422',
    zoom: '6',
  },
  {
    id: '23',
    enabled: '1',
    code3l: 'BRA',
    code2l: 'BR',
    name: 'Brazil',
    name_official: 'Federative Republic of Brazil',
    flag_32: 'BR-32.png',
    flag_128: 'BR-128.png',
    latitude: '-11.80965046',
    longitude: '-53.33152600',
    zoom: '4',
  },
  {
    id: '25',
    enabled: '1',
    code3l: 'BGR',
    code2l: 'BG',
    name: 'Bulgaria',
    name_official: 'Republic of Bulgaria',
    flag_32: 'BG-32.png',
    flag_128: 'BG-128.png',
    latitude: '42.70160678',
    longitude: '25.48583200',
    zoom: '7',
  },
  {
    id: '26',
    enabled: '1',
    code3l: 'BFA',
    code2l: 'BF',
    name: 'Burkina Faso',
    name_official: 'Burkina Faso',
    flag_32: 'BF-32.png',
    flag_128: 'BF-128.png',
    latitude: '12.22492458',
    longitude: '-1.56159100',
    zoom: '7',
  },
  {
    id: '27',
    enabled: '1',
    code3l: 'BDI',
    code2l: 'BI',
    name: 'Burundi',
    name_official: 'Republic of Burundi',
    flag_32: 'BI-32.png',
    flag_128: 'BI-128.png',
    latitude: '-3.40499707',
    longitude: '29.88592902',
    zoom: '8',
  },
  {
    id: '31',
    enabled: '1',
    code3l: 'CPV',
    code2l: 'CV',
    name: 'Cabo Verde',
    name_official: 'Republic of Cabo Verde',
    flag_32: 'CV-32.png',
    flag_128: 'CV-128.png',
    latitude: '15.11988711',
    longitude: '-23.60517010',
    zoom: '10',
  },
  {
    id: '28',
    enabled: '1',
    code3l: 'KHM',
    code2l: 'KH',
    name: 'Cambodia',
    name_official: 'Kingdom of Cambodia',
    flag_32: 'KH-32.png',
    flag_128: 'KH-128.png',
    latitude: '12.83288883',
    longitude: '104.84814273',
    zoom: '7',
  },
  {
    id: '29',
    enabled: '1',
    code3l: 'CMR',
    code2l: 'CM',
    name: 'Cameroon',
    name_official: 'Republic of Cameroon',
    flag_32: 'CM-32.png',
    flag_128: 'CM-128.png',
    latitude: '7.38622543',
    longitude: '12.72825915',
    zoom: '6',
  },
  {
    id: '30',
    enabled: '1',
    code3l: 'CAN',
    code2l: 'CA',
    name: 'Canada',
    name_official: 'Canada',
    flag_32: 'CA-32.png',
    flag_128: 'CA-128.png',
    latitude: '60.36196817',
    longitude: '-106.69833150',
    zoom: '4',
  },
  {
    id: '222',
    enabled: '1',
    code3l: 'CYM',
    code2l: 'KY',
    name: 'Cayman Islands',
    name_official: 'The Cayman Islands',
    flag_32: 'KY-32.png',
    flag_128: 'KY-128.png',
    latitude: '19.31322102',
    longitude: '-81.25459800',
    zoom: '11',
  },
  {
    id: '32',
    enabled: '1',
    code3l: 'CAF',
    code2l: 'CF',
    name: 'Central Africa',
    name_official: 'Central African Republic',
    flag_32: 'CF-32.png',
    flag_128: 'CF-128.png',
    latitude: '6.82541830',
    longitude: '20.64281514',
    zoom: '6',
  },
  {
    id: '33',
    enabled: '1',
    code3l: 'TCD',
    code2l: 'TD',
    name: 'Chad',
    name_official: 'Republic of Chad',
    flag_32: 'TD-32.png',
    flag_128: 'TD-128.png',
    latitude: '14.80342407',
    longitude: '18.78714064',
    zoom: '5',
  },
  {
    id: '34',
    enabled: '1',
    code3l: 'CHL',
    code2l: 'CL',
    name: 'Chile',
    name_official: 'Republic of Chile',
    flag_32: 'CL-32.png',
    flag_128: 'CL-128.png',
    latitude: '-38.01760790',
    longitude: '-71.40014474',
    zoom: '4',
  },
  {
    id: '35',
    enabled: '1',
    code3l: 'CHN',
    code2l: 'CN',
    name: 'China',
    name_official: "People's Republic of China",
    flag_32: 'CN-32.png',
    flag_128: 'CN-128.png',
    latitude: '36.71457440',
    longitude: '103.55819197',
    zoom: '4',
  },
  {
    id: '36',
    enabled: '1',
    code3l: 'COL',
    code2l: 'CO',
    name: 'Colombia',
    name_official: 'Republic of Colombia',
    flag_32: 'CO-32.png',
    flag_128: 'CO-128.png',
    latitude: '3.68182320',
    longitude: '-73.53927436',
    zoom: '5',
  },
  {
    id: '37',
    enabled: '1',
    code3l: 'COM',
    code2l: 'KM',
    name: 'Comoros',
    name_official: 'Union of the Comoros',
    flag_32: 'KM-32.png',
    flag_128: 'KM-128.png',
    latitude: '-11.64529989',
    longitude: '43.33330200',
    zoom: '10',
  },
  {
    id: '38',
    enabled: '1',
    code3l: 'COG',
    code2l: 'CG',
    name: 'Congo',
    name_official: 'Republic of the Congo',
    flag_32: 'CG-32.png',
    flag_128: 'CG-128.png',
    latitude: '-0.68967806',
    longitude: '15.69033190',
    zoom: '6',
  },
  {
    id: '39',
    enabled: '1',
    code3l: 'CRI',
    code2l: 'CR',
    name: 'Costa Rica',
    name_official: 'Republic of Costa Rica',
    flag_32: 'CR-32.png',
    flag_128: 'CR-128.png',
    latitude: '9.98427463',
    longitude: '-84.09949534',
    zoom: '8',
  },
  {
    id: '44',
    enabled: '1',
    code3l: 'CIV',
    code2l: 'CI',
    name: "Côte d'Ivoire",
    name_official: "Republic of Côte d'Ivoire",
    flag_32: 'CI-32.png',
    flag_128: 'CI-128.png',
    latitude: '7.59684148',
    longitude: '-5.49214636',
    zoom: '7',
  },
  {
    id: '40',
    enabled: '1',
    code3l: 'HRV',
    code2l: 'HR',
    name: 'Croatia',
    name_official: 'Republic of Croatia',
    flag_32: 'HR-32.png',
    flag_128: 'HR-128.png',
    latitude: '44.81372482',
    longitude: '16.29039507',
    zoom: '7',
  },
  {
    id: '41',
    enabled: '1',
    code3l: 'CUB',
    code2l: 'CU',
    name: 'Cuba',
    name_official: 'Republic of Cuba',
    flag_32: 'CU-32.png',
    flag_128: 'CU-128.png',
    latitude: '21.54513189',
    longitude: '-79.00064743',
    zoom: '6',
  },
  {
    id: '42',
    enabled: '1',
    code3l: 'CYP',
    code2l: 'CY',
    name: 'Cyprus',
    name_official: 'Republic of Cyprus',
    flag_32: 'CY-32.png',
    flag_128: 'CY-128.png',
    latitude: '35.12450768',
    longitude: '33.42986100',
    zoom: '9',
  },
  {
    id: '43',
    enabled: '1',
    code3l: 'CZE',
    code2l: 'CZ',
    name: 'Czechia',
    name_official: 'Czech Republic',
    flag_32: 'CZ-32.png',
    flag_128: 'CZ-128.png',
    latitude: '49.76026136',
    longitude: '15.53888197',
    zoom: '7',
  },
  {
    id: '183',
    enabled: '1',
    code3l: 'ZAR',
    code2l: 'CD',
    name: 'Democratic Republic of the Congo',
    name_official: 'Democratic Republic of the Congo',
    flag_32: 'CD-32.png',
    flag_128: 'CD-128.png',
    latitude: '-4.05373938',
    longitude: '23.01110741',
    zoom: '5',
  },
  {
    id: '45',
    enabled: '1',
    code3l: 'DNK',
    code2l: 'DK',
    name: 'Denmark',
    name_official: 'Kingdom of Denmark',
    flag_32: 'DK-32.png',
    flag_128: 'DK-128.png',
    latitude: '54.71794021',
    longitude: '9.41938953',
    zoom: '6',
  },
  {
    id: '46',
    enabled: '1',
    code3l: 'DJI',
    code2l: 'DJ',
    name: 'Djibouti',
    name_official: 'Republic of Djibouti',
    flag_32: 'DJ-32.png',
    flag_128: 'DJ-128.png',
    latitude: '11.75959257',
    longitude: '42.65344839',
    zoom: '8',
  },
  {
    id: '48',
    enabled: '1',
    code3l: 'DOM',
    code2l: 'DO',
    name: 'Dominican Republic',
    name_official: 'Dominican Republic',
    flag_32: 'DO-32.png',
    flag_128: 'DO-128.png',
    latitude: '18.73076761',
    longitude: '-70.16264900',
    zoom: '8',
  },
  {
    id: '49',
    enabled: '1',
    code3l: 'ECU',
    code2l: 'EC',
    name: 'Ecuador',
    name_official: 'Republic of Ecuador',
    flag_32: 'EC-32.png',
    flag_128: 'EC-128.png',
    latitude: '-1.22919037',
    longitude: '-78.55693916',
    zoom: '6',
  },
  {
    id: '50',
    enabled: '1',
    code3l: 'EGY',
    code2l: 'EG',
    name: 'Egypt',
    name_official: 'Arab Republic of Egypt',
    flag_32: 'EG-32.png',
    flag_128: 'EG-128.png',
    latitude: '26.71650873',
    longitude: '30.80250000',
    zoom: '6',
  },
  {
    id: '51',
    enabled: '1',
    code3l: 'SLV',
    code2l: 'SV',
    name: 'El Salvador',
    name_official: 'Republic of El Salvador',
    flag_32: 'SV-32.png',
    flag_128: 'SV-128.png',
    latitude: '13.79043561',
    longitude: '-88.89652800',
    zoom: '8',
  },
  {
    id: '52',
    enabled: '1',
    code3l: 'GNQ',
    code2l: 'GQ',
    name: 'Equatorial Guinea',
    name_official: 'Republic of Equatorial Guinea',
    flag_32: 'GQ-32.png',
    flag_128: 'GQ-128.png',
    latitude: '1.65068442',
    longitude: '10.26789700',
    zoom: '9',
  },
  {
    id: '53',
    enabled: '1',
    code3l: 'ERI',
    code2l: 'ER',
    name: 'Eritrea',
    name_official: 'State of Eritrea',
    flag_32: 'ER-32.png',
    flag_128: 'ER-128.png',
    latitude: '15.21227764',
    longitude: '39.61204792',
    zoom: '7',
  },
  {
    id: '54',
    enabled: '1',
    code3l: 'EST',
    code2l: 'EE',
    name: 'Estonia',
    name_official: 'Republic of Estonia',
    flag_32: 'EE-32.png',
    flag_128: 'EE-128.png',
    latitude: '58.74041141',
    longitude: '25.38165099',
    zoom: '7',
  },
  {
    id: '55',
    enabled: '1',
    code3l: 'ETH',
    code2l: 'ET',
    name: 'Ethiopia',
    name_official: 'Federal Democratic Republic of Ethiopia',
    flag_32: 'ET-32.png',
    flag_128: 'ET-128.png',
    latitude: '9.10727589',
    longitude: '39.84148164',
    zoom: '6',
  },
  {
    id: '56',
    enabled: '1',
    code3l: 'FJI',
    code2l: 'FJ',
    name: 'Fiji',
    name_official: 'Republic of Fiji',
    flag_32: 'FJ-32.png',
    flag_128: 'FJ-128.png',
    latitude: '-17.71219757',
    longitude: '178.06503600',
    zoom: '9',
  },
  {
    id: '57',
    enabled: '1',
    code3l: 'FIN',
    code2l: 'FI',
    name: 'Finland',
    name_official: 'Republic of Finland',
    flag_32: 'FI-32.png',
    flag_128: 'FI-128.png',
    latitude: '64.69610892',
    longitude: '26.36339137',
    zoom: '5',
  },
  {
    id: '58',
    enabled: '1',
    code3l: 'FRA',
    code2l: 'FR',
    name: 'France',
    name_official: 'French Republic',
    flag_32: 'FR-32.png',
    flag_128: 'FR-128.png',
    latitude: '46.48372145',
    longitude: '2.60926281',
    zoom: '6',
  },
  {
    id: '59',
    enabled: '1',
    code3l: 'GAB',
    code2l: 'GA',
    name: 'Gabon',
    name_official: 'Gabonese Republic',
    flag_32: 'GA-32.png',
    flag_128: 'GA-128.png',
    latitude: '-0.43426435',
    longitude: '11.43916591',
    zoom: '7',
  },
  {
    id: '61',
    enabled: '1',
    code3l: 'GEO',
    code2l: 'GE',
    name: 'Georgia',
    name_official: 'Georgia',
    flag_32: 'GE-32.png',
    flag_128: 'GE-128.png',
    latitude: '41.82754301',
    longitude: '44.17329916',
    zoom: '7',
  },
  {
    id: '62',
    enabled: '1',
    code3l: 'DEU',
    code2l: 'DE',
    name: 'Germany',
    name_official: 'Federal Republic of Germany',
    flag_32: 'DE-32.png',
    flag_128: 'DE-128.png',
    latitude: '50.82871201',
    longitude: '10.97887975',
    zoom: '6',
  },
  {
    id: '63',
    enabled: '1',
    code3l: 'GHA',
    code2l: 'GH',
    name: 'Ghana',
    name_official: 'Republic of Ghana',
    flag_32: 'GH-32.png',
    flag_128: 'GH-128.png',
    latitude: '7.69154199',
    longitude: '-1.29234904',
    zoom: '7',
  },
  {
    id: '64',
    enabled: '1',
    code3l: 'GRC',
    code2l: 'GR',
    name: 'Greece',
    name_official: 'Hellenic Republic',
    flag_32: 'GR-32.png',
    flag_128: 'GR-128.png',
    latitude: '38.52254746',
    longitude: '24.53794505',
    zoom: '6',
  },
  {
    id: '213',
    enabled: '1',
    code3l: 'GRL',
    code2l: 'GL',
    name: 'Greenland',
    name_official: 'Greenland',
    flag_32: 'GL-32.png',
    flag_128: 'GL-128.png',
    latitude: '71.42932629',
    longitude: '-34.38651956',
    zoom: '3',
  },
  {
    id: '65',
    enabled: '1',
    code3l: 'GRD',
    code2l: 'GD',
    name: 'Grenada',
    name_official: 'Grenada',
    flag_32: 'GD-32.png',
    flag_128: 'GD-128.png',
    latitude: '12.11644807',
    longitude: '-61.67899400',
    zoom: '11',
  },
  {
    id: '66',
    enabled: '1',
    code3l: 'GTM',
    code2l: 'GT',
    name: 'Guatemala',
    name_official: 'Republic of Guatemala',
    flag_32: 'GT-32.png',
    flag_128: 'GT-128.png',
    latitude: '15.72598421',
    longitude: '-89.96707712',
    zoom: '7',
  },
  {
    id: '67',
    enabled: '1',
    code3l: 'GIN',
    code2l: 'GN',
    name: 'Guinea',
    name_official: 'Republic of Guinea',
    flag_32: 'GN-32.png',
    flag_128: 'GN-128.png',
    latitude: '9.94301472',
    longitude: '-11.31711839',
    zoom: '7',
  },
  {
    id: '69',
    enabled: '1',
    code3l: 'GUY',
    code2l: 'GY',
    name: 'Guyana',
    name_official: 'Republic of Guyana',
    flag_32: 'GY-32.png',
    flag_128: 'GY-128.png',
    latitude: '4.47957059',
    longitude: '-58.72692293',
    zoom: '6',
  },
  {
    id: '70',
    enabled: '1',
    code3l: 'HTI',
    code2l: 'HT',
    name: 'Haiti',
    name_official: 'Republic of Haiti',
    flag_32: 'HT-32.png',
    flag_128: 'HT-128.png',
    latitude: '19.07430861',
    longitude: '-72.79607526',
    zoom: '8',
  },
  {
    id: '71',
    enabled: '1',
    code3l: 'HND',
    code2l: 'HN',
    name: 'Honduras',
    name_official: 'Republic of Honduras',
    flag_32: 'HN-32.png',
    flag_128: 'HN-128.png',
    latitude: '14.64994423',
    longitude: '-87.01643713',
    zoom: '7',
  },
  {
    id: '217',
    enabled: '1',
    code3l: 'HKG',
    code2l: 'HK',
    name: 'Hong Kong',
    name_official:
      "Hong Kong Special Administrative Region of the People's Republic",
    flag_32: 'HK-32.png',
    flag_128: 'HK-128.png',
    latitude: '22.33728531',
    longitude: '114.14657786',
    zoom: '11',
  },
  {
    id: '72',
    enabled: '1',
    code3l: 'HUN',
    code2l: 'HU',
    name: 'Hungary',
    name_official: 'Republic of Hungary',
    flag_32: 'HU-32.png',
    flag_128: 'HU-128.png',
    latitude: '46.97670384',
    longitude: '19.35499657',
    zoom: '7',
  },
  {
    id: '73',
    enabled: '1',
    code3l: 'ISL',
    code2l: 'IS',
    name: 'Iceland',
    name_official: 'Republic of Iceland',
    flag_32: 'IS-32.png',
    flag_128: 'IS-128.png',
    latitude: '64.99294495',
    longitude: '-18.57038755',
    zoom: '6',
  },
  {
    id: '74',
    enabled: '1',
    code3l: 'IND',
    code2l: 'IN',
    name: 'India',
    name_official: 'Republic of India',
    flag_32: 'IN-32.png',
    flag_128: 'IN-128.png',
    latitude: '20.46549519',
    longitude: '78.50146222',
    zoom: '4',
  },
  {
    id: '75',
    enabled: '1',
    code3l: 'IDN',
    code2l: 'ID',
    name: 'Indonesia',
    name_official: 'Republic of Indonesia',
    flag_32: 'ID-32.png',
    flag_128: 'ID-128.png',
    latitude: '-2.46229680',
    longitude: '121.18329789',
    zoom: '4',
  },
  {
    id: '187',
    enabled: '1',
    code3l: 'IRN',
    code2l: 'IR',
    name: 'Iran',
    name_official: 'Islamic Republic of Iran',
    flag_32: 'IR-32.png',
    flag_128: 'IR-128.png',
    latitude: '31.40240324',
    longitude: '51.28204814',
    zoom: '5',
  },
  {
    id: '76',
    enabled: '1',
    code3l: 'IRQ',
    code2l: 'IQ',
    name: 'Iraq',
    name_official: 'Republic of Iraq',
    flag_32: 'IQ-32.png',
    flag_128: 'IQ-128.png',
    latitude: '32.90170182',
    longitude: '43.19590056',
    zoom: '6',
  },
  {
    id: '77',
    enabled: '1',
    code3l: 'IRL',
    code2l: 'IE',
    name: 'Ireland',
    name_official: 'Ireland',
    flag_32: 'IE-32.png',
    flag_128: 'IE-128.png',
    latitude: '53.10101628',
    longitude: '-8.21092302',
    zoom: '6',
  },
  {
    id: '78',
    enabled: '1',
    code3l: 'ISR',
    code2l: 'IL',
    name: 'Israel',
    name_official: 'State of Israel',
    flag_32: 'IL-32.png',
    flag_128: 'IL-128.png',
    latitude: '30.85883075',
    longitude: '34.91753797',
    zoom: '7',
  },
  {
    id: '79',
    enabled: '1',
    code3l: 'ITA',
    code2l: 'IT',
    name: 'Italy',
    name_official: 'Republic of Italy',
    flag_32: 'IT-32.png',
    flag_128: 'IT-128.png',
    latitude: '41.77810840',
    longitude: '12.67725128',
    zoom: '5',
  },
  {
    id: '80',
    enabled: '1',
    code3l: 'JAM',
    code2l: 'JM',
    name: 'Jamaica',
    name_official: 'Jamaica',
    flag_32: 'JM-32.png',
    flag_128: 'JM-128.png',
    latitude: '18.10838487',
    longitude: '-77.29750600',
    zoom: '9',
  },
  {
    id: '81',
    enabled: '1',
    code3l: 'JPN',
    code2l: 'JP',
    name: 'Japan',
    name_official: 'Japan',
    flag_32: 'JP-32.png',
    flag_128: 'JP-128.png',
    latitude: '37.51848822',
    longitude: '137.67066061',
    zoom: '5',
  },
  {
    id: '82',
    enabled: '1',
    code3l: 'JOR',
    code2l: 'JO',
    name: 'Jordan',
    name_official: 'Hashemite Kingdom of Jordan',
    flag_32: 'JO-32.png',
    flag_128: 'JO-128.png',
    latitude: '31.31616588',
    longitude: '36.37575510',
    zoom: '7',
  },
  {
    id: '83',
    enabled: '1',
    code3l: 'KAZ',
    code2l: 'KZ',
    name: 'Kazakhstan',
    name_official: 'Republic of Kazakhstan',
    flag_32: 'KZ-32.png',
    flag_128: 'KZ-128.png',
    latitude: '45.38592596',
    longitude: '68.81334444',
    zoom: '4',
  },
  {
    id: '84',
    enabled: '1',
    code3l: 'KEN',
    code2l: 'KE',
    name: 'Kenya',
    name_official: 'Republic of Kenya',
    flag_32: 'KE-32.png',
    flag_128: 'KE-128.png',
    latitude: '0.19582452',
    longitude: '37.97212297',
    zoom: '6',
  },
  {
    id: '86',
    enabled: '1',
    code3l: 'KWT',
    code2l: 'KW',
    name: 'Kuwait',
    name_official: 'State of Kuwait',
    flag_32: 'KW-32.png',
    flag_128: 'KW-128.png',
    latitude: '29.43253341',
    longitude: '47.71798405',
    zoom: '8',
  },
  {
    id: '87',
    enabled: '1',
    code3l: 'KGZ',
    code2l: 'KG',
    name: 'Kyrgyzstan',
    name_official: 'Kyrgyz Republic',
    flag_32: 'KG-32.png',
    flag_128: 'KG-128.png',
    latitude: '41.11509878',
    longitude: '74.25524574',
    zoom: '6',
  },
  {
    id: '88',
    enabled: '1',
    code3l: 'LAO',
    code2l: 'LA',
    name: 'Laos',
    name_official: 'Lao People\u2019s Democratic Republic',
    flag_32: 'LA-32.png',
    flag_128: 'LA-128.png',
    latitude: '17.76075593',
    longitude: '103.61611347',
    zoom: '6',
  },
  {
    id: '89',
    enabled: '1',
    code3l: 'LVA',
    code2l: 'LV',
    name: 'Latvia',
    name_official: 'Republic of Latvia',
    flag_32: 'LV-32.png',
    flag_128: 'LV-128.png',
    latitude: '56.86697515',
    longitude: '24.54826936',
    zoom: '7',
  },
  {
    id: '90',
    enabled: '1',
    code3l: 'LBN',
    code2l: 'LB',
    name: 'Lebanon',
    name_official: 'Lebanese Republic',
    flag_32: 'LB-32.png',
    flag_128: 'LB-128.png',
    latitude: '34.08249284',
    longitude: '35.66454309',
    zoom: '8',
  },
  {
    id: '92',
    enabled: '1',
    code3l: 'LBR',
    code2l: 'LR',
    name: 'Liberia',
    name_official: 'Republic of Liberia',
    flag_32: 'LR-32.png',
    flag_128: 'LR-128.png',
    latitude: '6.44154681',
    longitude: '-9.39103485',
    zoom: '7',
  },
  {
    id: '93',
    enabled: '1',
    code3l: 'LBY',
    code2l: 'LY',
    name: 'Libya',
    name_official: 'Socialist People\u2019s Libyan Arab Jamahiriya',
    flag_32: 'LY-32.png',
    flag_128: 'LY-128.png',
    latitude: '27.06902914',
    longitude: '18.19513987',
    zoom: '5',
  },
  {
    id: '94',
    enabled: '1',
    code3l: 'LIE',
    code2l: 'LI',
    name: 'Liechtenstein',
    name_official: 'Principality of Liechtenstein',
    flag_32: 'LI-32.png',
    flag_128: 'LI-128.png',
    latitude: '47.16587383',
    longitude: '9.55537700',
    zoom: '11',
  },
  {
    id: '95',
    enabled: '1',
    code3l: 'LTU',
    code2l: 'LT',
    name: 'Lithuania',
    name_official: 'Republic of Lithuania',
    flag_32: 'LT-32.png',
    flag_128: 'LT-128.png',
    latitude: '55.25095948',
    longitude: '23.80987587',
    zoom: '7',
  },
  {
    id: '96',
    enabled: '1',
    code3l: 'LUX',
    code2l: 'LU',
    name: 'Luxembourg',
    name_official: 'Grand Duchy of Luxembourg',
    flag_32: 'LU-32.png',
    flag_128: 'LU-128.png',
    latitude: '49.81327712',
    longitude: '6.12958700',
    zoom: '9',
  },
  {
    id: '191',
    enabled: '1',
    code3l: 'MKD',
    code2l: 'MK',
    name: 'Macedonia',
    name_official: 'The former Yugoslav Republic of Macedonia',
    flag_32: 'MK-32.png',
    flag_128: 'MK-128.png',
    latitude: '41.60059479',
    longitude: '21.74527900',
    zoom: '8',
  },
  {
    id: '97',
    enabled: '1',
    code3l: 'MDG',
    code2l: 'MG',
    name: 'Madagascar',
    name_official: 'Republic of Madagascar',
    flag_32: 'MG-32.png',
    flag_128: 'MG-128.png',
    latitude: '-19.79858543',
    longitude: '46.97898228',
    zoom: '5',
  },
  {
    id: '98',
    enabled: '1',
    code3l: 'MWI',
    code2l: 'MW',
    name: 'Malawi',
    name_official: 'Republic of Malawi',
    flag_32: 'MW-32.png',
    flag_128: 'MW-128.png',
    latitude: '-12.48684092',
    longitude: '34.14223524',
    zoom: '6',
  },
  {
    id: '99',
    enabled: '1',
    code3l: 'MYS',
    code2l: 'MY',
    name: 'Malaysia',
    name_official: 'Malaysia',
    flag_32: 'MY-32.png',
    flag_128: 'MY-128.png',
    latitude: '4.97345793',
    longitude: '106.54609050',
    zoom: '5',
  },
  {
    id: '100',
    enabled: '1',
    code3l: 'MDV',
    code2l: 'MV',
    name: 'Maldives',
    name_official: 'Republic of Maldives',
    flag_32: 'MV-32.png',
    flag_128: 'MV-128.png',
    latitude: '-0.64224221',
    longitude: '73.13373313',
    zoom: '12',
  },
  {
    id: '101',
    enabled: '1',
    code3l: 'MLI',
    code2l: 'ML',
    name: 'Mali',
    name_official: 'Republic of Mali',
    flag_32: 'ML-32.png',
    flag_128: 'ML-128.png',
    latitude: '17.69385811',
    longitude: '-1.96368730',
    zoom: '5',
  },
  {
    id: '102',
    enabled: '1',
    code3l: 'MLT',
    code2l: 'MT',
    name: 'Malta',
    name_official: 'Republic of Malta',
    flag_32: 'MT-32.png',
    flag_128: 'MT-128.png',
    latitude: '35.89706403',
    longitude: '14.43687877',
    zoom: '11',
  },
  {
    id: '104',
    enabled: '1',
    code3l: 'MRT',
    code2l: 'MR',
    name: 'Mauritania',
    name_official: 'Islamic Republic of Mauritania',
    flag_32: 'MR-32.png',
    flag_128: 'MR-128.png',
    latitude: '20.28331239',
    longitude: '-10.21573334',
    zoom: '5',
  },
  {
    id: '105',
    enabled: '1',
    code3l: 'MUS',
    code2l: 'MU',
    name: 'Mauritius',
    name_official: 'Republic of Mauritius',
    flag_32: 'MU-32.png',
    flag_128: 'MU-128.png',
    latitude: '-20.28368188',
    longitude: '57.56588291',
    zoom: '10',
  },
  {
    id: '106',
    enabled: '1',
    code3l: 'MEX',
    code2l: 'MX',
    name: 'Mexico',
    name_official: 'United Mexican States',
    flag_32: 'MX-32.png',
    flag_128: 'MX-128.png',
    latitude: '22.92036676',
    longitude: '-102.33305344',
    zoom: '5',
  },
  {
    id: '190',
    enabled: '1',
    code3l: 'MDA',
    code2l: 'MD',
    name: 'Moldova',
    name_official: 'Republic of Moldova',
    flag_32: 'MD-32.png',
    flag_128: 'MD-128.png',
    latitude: '47.10710437',
    longitude: '28.54018109',
    zoom: '7',
  },
  {
    id: '108',
    enabled: '1',
    code3l: 'MNG',
    code2l: 'MN',
    name: 'Mongolia',
    name_official: 'Mongolia',
    flag_32: 'MN-32.png',
    flag_128: 'MN-128.png',
    latitude: '46.80556270',
    longitude: '104.30808978',
    zoom: '5',
  },
  {
    id: '109',
    enabled: '1',
    code3l: 'MNE',
    code2l: 'ME',
    name: 'Montenegro',
    name_official: 'Montenegro',
    flag_32: 'ME-32.png',
    flag_128: 'ME-128.png',
    latitude: '42.71699590',
    longitude: '19.09699321',
    zoom: '8',
  },
  {
    id: '110',
    enabled: '1',
    code3l: 'MAR',
    code2l: 'MA',
    name: 'Morocco',
    name_official: 'Kingdom of Morocco',
    flag_32: 'MA-32.png',
    flag_128: 'MA-128.png',
    latitude: '31.95441758',
    longitude: '-7.26839325',
    zoom: '6',
  },
  {
    id: '111',
    enabled: '1',
    code3l: 'MOZ',
    code2l: 'MZ',
    name: 'Mozambique',
    name_official: 'Republic of Mozambique',
    flag_32: 'MZ-32.png',
    flag_128: 'MZ-128.png',
    latitude: '-19.07617816',
    longitude: '33.81570282',
    zoom: '5',
  },
  {
    id: '112',
    enabled: '1',
    code3l: 'MMR',
    code2l: 'MM',
    name: 'Myanmar',
    name_official: 'Union of Myanmar',
    flag_32: 'MM-32.png',
    flag_128: 'MM-128.png',
    latitude: '19.20985380',
    longitude: '96.54949272',
    zoom: '5',
  },
  {
    id: '113',
    enabled: '1',
    code3l: 'NAM',
    code2l: 'NA',
    name: 'Namibia',
    name_official: 'Republic of Namibia',
    flag_32: 'NA-32.png',
    flag_128: 'NA-128.png',
    latitude: '-22.70965620',
    longitude: '16.72161918',
    zoom: '6',
  },
  {
    id: '115',
    enabled: '1',
    code3l: 'NPL',
    code2l: 'NP',
    name: 'Nepal',
    name_official: 'Federal Democratic Republic of Nepal',
    flag_32: 'NP-32.png',
    flag_128: 'NP-128.png',
    latitude: '28.28430770',
    longitude: '83.98119373',
    zoom: '7',
  },
  {
    id: '116',
    enabled: '1',
    code3l: 'NLD',
    code2l: 'NL',
    name: 'Netherlands',
    name_official: 'Kingdom of the Netherlands',
    flag_32: 'NL-32.png',
    flag_128: 'NL-128.png',
    latitude: '52.33939951',
    longitude: '4.98914998',
    zoom: '7',
  },
  {
    id: '117',
    enabled: '1',
    code3l: 'NZL',
    code2l: 'NZ',
    name: 'New Zealand',
    name_official: 'New Zealand',
    flag_32: 'NZ-32.png',
    flag_128: 'NZ-128.png',
    latitude: '-40.95025298',
    longitude: '171.76586181',
    zoom: '5',
  },
  {
    id: '118',
    enabled: '1',
    code3l: 'NIC',
    code2l: 'NI',
    name: 'Nicaragua',
    name_official: 'Republic of Nicaragua',
    flag_32: 'NI-32.png',
    flag_128: 'NI-128.png',
    latitude: '12.91806226',
    longitude: '-84.82270352',
    zoom: '7',
  },
  {
    id: '119',
    enabled: '1',
    code3l: 'NER',
    code2l: 'NE',
    name: 'Niger',
    name_official: 'Republic of the Niger',
    flag_32: 'NE-32.png',
    flag_128: 'NE-128.png',
    latitude: '17.23446679',
    longitude: '8.23547860',
    zoom: '6',
  },
  {
    id: '120',
    enabled: '1',
    code3l: 'NGA',
    code2l: 'NG',
    name: 'Nigeria',
    name_official: 'Federal Republic of Nigeria',
    flag_32: 'NG-32.png',
    flag_128: 'NG-128.png',
    latitude: '9.02165273',
    longitude: '7.82933373',
    zoom: '6',
  },
  {
    id: '121',
    enabled: '1',
    code3l: 'NOR',
    code2l: 'NO',
    name: 'Norway',
    name_official: 'Kingdom of Norway',
    flag_32: 'NO-32.png',
    flag_128: 'NO-128.png',
    latitude: '65.04680297',
    longitude: '13.50069228',
    zoom: '4',
  },
  {
    id: '122',
    enabled: '1',
    code3l: 'OMN',
    code2l: 'OM',
    name: 'Oman',
    name_official: 'Sultanate of Oman',
    flag_32: 'OM-32.png',
    flag_128: 'OM-128.png',
    latitude: '20.69906846',
    longitude: '56.69230596',
    zoom: '6',
  },
  {
    id: '123',
    enabled: '1',
    code3l: 'PAK',
    code2l: 'PK',
    name: 'Pakistan',
    name_official: 'Islamic Republic of Pakistan',
    flag_32: 'PK-32.png',
    flag_128: 'PK-128.png',
    latitude: '29.90335974',
    longitude: '70.34487986',
    zoom: '5',
  },
  {
    id: '127',
    enabled: '1',
    code3l: 'PRY',
    code2l: 'PY',
    name: 'Paraguay',
    name_official: 'Republic of Paraguay',
    flag_32: 'PY-32.png',
    flag_128: 'PY-128.png',
    latitude: '-23.38564782',
    longitude: '-58.29551057',
    zoom: '6',
  },
  {
    id: '128',
    enabled: '1',
    code3l: 'PER',
    code2l: 'PE',
    name: 'Peru',
    name_official: 'Republic of Peru',
    flag_32: 'PE-32.png',
    flag_128: 'PE-128.png',
    latitude: '-8.50205247',
    longitude: '-76.15772412',
    zoom: '5',
  },
  {
    id: '129',
    enabled: '1',
    code3l: 'PHL',
    code2l: 'PH',
    name: 'Philippines',
    name_official: 'Republic of the Philippines',
    flag_32: 'PH-32.png',
    flag_128: 'PH-128.png',
    latitude: '12.82361200',
    longitude: '121.77401700',
    zoom: '6',
  },
  {
    id: '130',
    enabled: '1',
    code3l: 'POL',
    code2l: 'PL',
    name: 'Poland',
    name_official: 'Republic of Poland',
    flag_32: 'PL-32.png',
    flag_128: 'PL-128.png',
    latitude: '52.10117636',
    longitude: '19.33190957',
    zoom: '6',
  },
  {
    id: '131',
    enabled: '1',
    code3l: 'PRT',
    code2l: 'PT',
    name: 'Portugal',
    name_official: 'Portuguese Republic',
    flag_32: 'PT-32.png',
    flag_128: 'PT-128.png',
    latitude: '39.44879136',
    longitude: '-8.03768042',
    zoom: '6',
  },
  {
    id: '233',
    enabled: '1',
    code3l: 'PRI',
    code2l: 'PR',
    name: 'Puerto Rico',
    name_official: 'Commonwealth of Puerto Rico',
    flag_32: 'PR-32.png',
    flag_128: 'PR-128.png',
    latitude: '18.21963053',
    longitude: '-66.59015100',
    zoom: '9',
  },
  {
    id: '132',
    enabled: '1',
    code3l: 'QAT',
    code2l: 'QA',
    name: 'Qatar',
    name_official: 'State of Qatar',
    flag_32: 'QA-32.png',
    flag_128: 'QA-128.png',
    latitude: '25.24551555',
    longitude: '51.24431480',
    zoom: '8',
  },
  {
    id: '133',
    enabled: '1',
    code3l: 'ROU',
    code2l: 'RO',
    name: 'Romania',
    name_official: 'Romania',
    flag_32: 'RO-32.png',
    flag_128: 'RO-128.png',
    latitude: '45.56450023',
    longitude: '25.21945155',
    zoom: '6',
  },
  {
    id: '134',
    enabled: '1',
    code3l: 'RUS',
    code2l: 'RU',
    name: 'Russia',
    name_official: 'Russian Federation',
    flag_32: 'RU-32.png',
    flag_128: 'RU-128.png',
    latitude: '57.96812298',
    longitude: '102.41837137',
    zoom: '3',
  },
  {
    id: '135',
    enabled: '1',
    code3l: 'RWA',
    code2l: 'RW',
    name: 'Rwanda',
    name_official: 'Republic of Rwanda',
    flag_32: 'RW-32.png',
    flag_128: 'RW-128.png',
    latitude: '-1.98589079',
    longitude: '29.94255855',
    zoom: '8',
  },
  {
    id: '142',
    enabled: '1',
    code3l: 'SAU',
    code2l: 'SA',
    name: 'Saudi Arabia',
    name_official: 'Kingdom of Saudi Arabia',
    flag_32: 'SA-32.png',
    flag_128: 'SA-128.png',
    latitude: '24.16687314',
    longitude: '42.88190638',
    zoom: '5',
  },
  {
    id: '143',
    enabled: '1',
    code3l: 'SEN',
    code2l: 'SN',
    name: 'Senegal',
    name_official: 'Republic of Senegal',
    flag_32: 'SN-32.png',
    flag_128: 'SN-128.png',
    latitude: '14.43579003',
    longitude: '-14.68306489',
    zoom: '7',
  },
  {
    id: '144',
    enabled: '1',
    code3l: 'SRB',
    code2l: 'RS',
    name: 'Serbia',
    name_official: 'Republic of Serbia',
    flag_32: 'RS-32.png',
    flag_128: 'RS-128.png',
    latitude: '44.06736041',
    longitude: '20.29725084',
    zoom: '7',
  },
  {
    id: '146',
    enabled: '1',
    code3l: 'SLE',
    code2l: 'SL',
    name: 'Sierra Leone',
    name_official: 'Republic of Sierra Leone',
    flag_32: 'SL-32.png',
    flag_128: 'SL-128.png',
    latitude: '8.45575589',
    longitude: '-11.93368759',
    zoom: '8',
  },
  {
    id: '147',
    enabled: '1',
    code3l: 'SGP',
    code2l: 'SG',
    name: 'Singapore',
    name_official: 'Republic of Singapore',
    flag_32: 'SG-32.png',
    flag_128: 'SG-128.png',
    latitude: '1.33873261',
    longitude: '103.83323559',
    zoom: '11',
  },
  {
    id: '148',
    enabled: '1',
    code3l: 'SVK',
    code2l: 'SK',
    name: 'Slovakia',
    name_official: 'Slovak Republic',
    flag_32: 'SK-32.png',
    flag_128: 'SK-128.png',
    latitude: '48.66923253',
    longitude: '19.75396564',
    zoom: '7',
  },
  {
    id: '149',
    enabled: '1',
    code3l: 'SVN',
    code2l: 'SI',
    name: 'Slovenia',
    name_official: 'Republic of Slovenia',
    flag_32: 'SI-32.png',
    flag_128: 'SI-128.png',
    latitude: '46.14315048',
    longitude: '14.99546300',
    zoom: '8',
  },
  {
    id: '151',
    enabled: '1',
    code3l: 'SOM',
    code2l: 'SO',
    name: 'Somalia',
    name_official: 'Somali Republic',
    flag_32: 'SO-32.png',
    flag_128: 'SO-128.png',
    latitude: '2.87224619',
    longitude: '45.27676444',
    zoom: '7',
  },
  {
    id: '152',
    enabled: '1',
    code3l: 'ZAF',
    code2l: 'ZA',
    name: 'South Africa',
    name_official: 'Republic of South Africa',
    flag_32: 'ZA-32.png',
    flag_128: 'ZA-128.png',
    latitude: '-27.17706863',
    longitude: '24.50856092',
    zoom: '5',
  },
  {
    id: '189',
    enabled: '1',
    code3l: 'KOR',
    code2l: 'KR',
    name: 'South Korea',
    name_official: 'Republic of Korea',
    flag_32: 'KR-32.png',
    flag_128: 'KR-128.png',
    latitude: '36.56344139',
    longitude: '127.51424646',
    zoom: '7',
  },
  {
    id: '250',
    enabled: '1',
    code3l: 'SSD',
    code2l: 'SS',
    name: 'South Sudan',
    name_official: 'Republic of South Sudan',
    flag_32: 'SS-32.png',
    flag_128: 'SS-128.png',
    latitude: '7.91320803',
    longitude: '30.15342434',
    zoom: '6',
  },
  {
    id: '153',
    enabled: '1',
    code3l: 'ESP',
    code2l: 'ES',
    name: 'Spain',
    name_official: 'Kingdom of Spain',
    flag_32: 'ES-32.png',
    flag_128: 'ES-128.png',
    latitude: '39.87299401',
    longitude: '-3.67089492',
    zoom: '6',
  },
  {
    id: '154',
    enabled: '1',
    code3l: 'LKA',
    code2l: 'LK',
    name: 'Sri Lanka',
    name_official: 'Democratic Socialist Republic of Sri  Lanka',
    flag_32: 'LK-32.png',
    flag_128: 'LK-128.png',
    latitude: '7.61264985',
    longitude: '80.83772497',
    zoom: '7',
  },
  {
    id: '155',
    enabled: '1',
    code3l: 'SDN',
    code2l: 'SD',
    name: 'Sudan',
    name_official: 'Republic of the Sudan',
    flag_32: 'SD-32.png',
    flag_128: 'SD-128.png',
    latitude: '15.96646839',
    longitude: '30.37145459',
    zoom: '5',
  },
  {
    id: '157',
    enabled: '1',
    code3l: 'SWZ',
    code2l: 'SZ',
    name: 'Swaziland',
    name_official: 'Kingdom of Swaziland',
    flag_32: 'SZ-32.png',
    flag_128: 'SZ-128.png',
    latitude: '-26.53892570',
    longitude: '31.47960891',
    zoom: '9',
  },
  {
    id: '158',
    enabled: '1',
    code3l: 'SWE',
    code2l: 'SE',
    name: 'Sweden',
    name_official: 'Kingdom of Sweden',
    flag_32: 'SE-32.png',
    flag_128: 'SE-128.png',
    latitude: '61.42370427',
    longitude: '16.73188991',
    zoom: '4',
  },
  {
    id: '159',
    enabled: '1',
    code3l: 'CHE',
    code2l: 'CH',
    name: 'Switzerland',
    name_official: 'Swiss Confederation',
    flag_32: 'CH-32.png',
    flag_128: 'CH-128.png',
    latitude: '46.81010721',
    longitude: '8.22751200',
    zoom: '8',
  },
  {
    id: '160',
    enabled: '1',
    code3l: 'SYR',
    code2l: 'SY',
    name: 'Syria',
    name_official: 'Syrian Arab Republic',
    flag_32: 'SY-32.png',
    flag_128: 'SY-128.png',
    latitude: '34.71097430',
    longitude: '38.66723516',
    zoom: '6',
  },
  {
    id: '242',
    enabled: '1',
    code3l: 'TWN',
    code2l: 'TW',
    name: 'Taiwan',
    name_official: 'Republic of China',
    flag_32: 'TW-32.png',
    flag_128: 'TW-128.png',
    latitude: '23.71891402',
    longitude: '121.10884043',
    zoom: '7',
  },
  {
    id: '193',
    enabled: '1',
    code3l: 'TZA',
    code2l: 'TZ',
    name: 'Tanzania',
    name_official: 'United Republic of Tanzania',
    flag_32: 'TZ-32.png',
    flag_128: 'TZ-128.png',
    latitude: '-6.37551085',
    longitude: '34.85587302',
    zoom: '6',
  },
  {
    id: '162',
    enabled: '1',
    code3l: 'THA',
    code2l: 'TH',
    name: 'Thailand',
    name_official: 'Kingdom of Thailand',
    flag_32: 'TH-32.png',
    flag_128: 'TH-128.png',
    latitude: '14.60009810',
    longitude: '101.38805881',
    zoom: '5',
  },
  {
    id: '60',
    enabled: '1',
    code3l: 'GMB',
    code2l: 'GM',
    name: 'The Gambia',
    name_official: 'Republic of The Gambia',
    flag_32: 'GM-32.png',
    flag_128: 'GM-128.png',
    latitude: '13.15921146',
    longitude: '-15.35956748',
    zoom: '8',
  },
  {
    id: '164',
    enabled: '1',
    code3l: 'TGO',
    code2l: 'TG',
    name: 'Togo',
    name_official: 'Togolese Republic',
    flag_32: 'TG-32.png',
    flag_128: 'TG-128.png',
    latitude: '8.68089206',
    longitude: '0.86049757',
    zoom: '7',
  },
  {
    id: '166',
    enabled: '1',
    code3l: 'TTO',
    code2l: 'TT',
    name: 'Trinidad & Tobago',
    name_official: 'Republic of Trinidad & Tobago',
    latitude: '10.43241863',
    longitude: '-61.22250300',
    flag_32: 'TT-32.png',
    flag_128: 'TT-128.png',
    zoom: 10,
  },
  {
    id: '167',
    enabled: '1',
    code3l: 'TUN',
    code2l: 'TN',
    name: 'Tunisia',
    name_official: 'Republic of Tunisia',
    flag_32: 'TN-32.png',
    flag_128: 'TN-128.png',
    latitude: '33.88431940',
    longitude: '9.71878341',
    zoom: '6',
  },
  {
    id: '168',
    enabled: '1',
    code3l: 'TUR',
    code2l: 'TR',
    name: 'Turkey',
    name_official: 'Republic of Turkey',
    flag_32: 'TR-32.png',
    flag_128: 'TR-128.png',
    latitude: '38.27069555',
    longitude: '36.28703317',
    zoom: '5',
  },
  {
    id: '171',
    enabled: '1',
    code3l: 'UGA',
    code2l: 'UG',
    name: 'Uganda',
    name_official: 'Republic of Uganda',
    flag_32: 'UG-32.png',
    flag_128: 'UG-128.png',
    latitude: '1.54760620',
    longitude: '32.44409759',
    zoom: '7',
  },
  {
    id: '172',
    enabled: '1',
    code3l: 'UKR',
    code2l: 'UA',
    name: 'Ukraine',
    name_official: 'Ukraine',
    flag_32: 'UA-32.png',
    flag_128: 'UA-128.png',
    latitude: '48.89358596',
    longitude: '31.10516920',
    zoom: '6',
  },
  {
    id: '173',
    enabled: '1',
    code3l: 'ARE',
    code2l: 'AE',
    name: 'United Arab Emirates',
    name_official: 'United Arab Emirates',
    flag_32: 'AE-32.png',
    flag_128: 'AE-128.png',
    latitude: '24.64324405',
    longitude: '53.62261227',
    zoom: '7',
  },
  {
    id: '186',
    enabled: '1',
    code3l: 'GBR',
    code2l: 'GB',
    name: 'United Kingdom',
    name_official: 'United Kingdom of Great Britain and Northern Ireland',
    flag_32: 'GB-32.png',
    flag_128: 'GB-128.png',
    latitude: '53.36540813',
    longitude: '-2.72184767',
    zoom: '6',
  },
  {
    id: '244',
    enabled: '1',
    code3l: 'USA',
    code2l: 'US',
    name: 'United States',
    name_official: 'United States of America',
    flag_32: 'US-32.png',
    flag_128: 'US-128.png',
    latitude: '37.66895362',
    longitude: '-102.39256450',
    zoom: '4',
  },
  {
    id: '174',
    enabled: '1',
    code3l: 'URY',
    code2l: 'UY',
    name: 'Uruguay',
    name_official: 'Eastern Republic of Uruguay',
    flag_32: 'UY-32.png',
    flag_128: 'UY-128.png',
    latitude: '-32.49342987',
    longitude: '-55.76583300',
    zoom: '7',
  },
  {
    id: '175',
    enabled: '1',
    code3l: 'UZB',
    code2l: 'UZ',
    name: 'Uzbekistan',
    name_official: 'Republic of Uzbekistan',
    flag_32: 'UZ-32.png',
    flag_128: 'UZ-128.png',
    latitude: '41.30829147',
    longitude: '63.37923650',
    zoom: '6',
  },
  {
    id: '181',
    enabled: '1',
    code3l: 'VUT',
    code2l: 'VU',
    name: 'Vanuatu',
    name_official: 'Republic of Vanuatu',
    flag_32: 'VU-32.png',
    flag_128: 'VU-128.png',
    latitude: '-17.00000000',
    longitude: '168.00000000',
    zoom: '8',
  },
  {
    id: '176',
    enabled: '1',
    code3l: 'VAT',
    code2l: 'VA',
    name: 'Vatican City',
    name_official: 'State of the Vatican City',
    flag_32: 'VA-32.png',
    flag_128: 'VA-128.png',
    latitude: '41.90324404',
    longitude: '12.45325784',
    zoom: '15',
  },
  {
    id: '194',
    enabled: '1',
    code3l: 'VEN',
    code2l: 'VE',
    name: 'Venezuela',
    name_official: 'Bolivarian Republic of Venezuela',
    flag_32: 'VE-32.png',
    flag_128: 'VE-128.png',
    latitude: '5.98477766',
    longitude: '-65.94152264',
    zoom: '6',
  },
  {
    id: '177',
    enabled: '1',
    code3l: 'VNM',
    code2l: 'VN',
    name: 'Viet Nam',
    name_official: 'Socialist Republic of Viet Nam',
    flag_32: 'VN-32.png',
    flag_128: 'VN-128.png',
    latitude: '17.19931699',
    longitude: '107.14012804',
    zoom: '5',
  },
  {
    id: '178',
    enabled: '1',
    code3l: 'YEM',
    code2l: 'YE',
    name: 'Yemen',
    name_official: 'Republic of Yemen',
    flag_32: 'YE-32.png',
    flag_128: 'YE-128.png',
    latitude: '15.60865453',
    longitude: '47.60453676',
    zoom: '6',
  },
  {
    id: '179',
    enabled: '1',
    code3l: 'ZMB',
    code2l: 'ZM',
    name: 'Zambia',
    name_official: 'Republic of Zambia',
    flag_32: 'ZM-32.png',
    flag_128: 'ZM-128.png',
    latitude: '-13.01812188',
    longitude: '28.33274444',
    zoom: '6',
  },
  {
    id: '180',
    enabled: '1',
    code3l: 'ZWE',
    code2l: 'ZW',
    name: 'Zimbabwe',
    name_official: 'Republic of Zimbabwe',
    flag_32: 'ZW-32.png',
    flag_128: 'ZW-128.png',
    latitude: '-19.00784952',
    longitude: '30.18758584',
    zoom: '6',
  },
].map((e) => ({
  ...e,
  name: e.name.toLowerCase().replace(/ /g, '-'),
})) as readonly Country[]
